import dayjs from 'dayjs';
import { Anchor, Table, Text, ThemeIcon, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Member, displayMemberGender, displayMemberGroups } from '@/models/Member';
import NoDataTable from '../Common/Table/NoDataTable';
import useShowModal from '../Modals/showModal';
import DeleteMember from '@/containers/Members/DeleteMember';
import TableActionsMenu from '../Common/Table/TableActionMenu';

interface MembersTableProps {
  data: Member[];
}

const MembersTable = ({ data }: MembersTableProps) => {
  const showModal = useShowModal();
  const navigate = useNavigate();

  const openDeleteModal = (memberId: string) => {
    showModal({
      id: 'delete-member',
      title: 'Izbriši polaznika',
      children: <DeleteMember memberId={memberId} />,
      size: 'md',
    });
  };

  const handleRowsRender = () => {
    if (data.length === 0) return <NoDataTable />;

    return data.map((member) => (
      <Table.Tr key={member.id}>
        <Table.Td style={{ cursor: 'pointer' }} onClick={() => navigate(`/members/${member.id}`)}>
          <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
            {member.full_name}
          </Anchor>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {dayjs(member.birth_date).format('YYYY')}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {displayMemberGender(member.gender)}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            {member.groups.length ? displayMemberGroups(member.groups) : '-'}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text c="gray.7" fw={400} size="sm">
            <ParentAccount hasParentAccount={member.parents.length > 0} />
          </Text>
        </Table.Td>
        <Table.Td style={{ cursor: 'pointer' }}>
          <TableActionsMenu optionsHandler={() => openDeleteModal(member.id)} />
        </Table.Td>
      </Table.Tr>
    ));
  };

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead style={{ backgroundColor: '#F6F8FA' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Ime
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Godina rođenja
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Pol
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Grupa
            </Title>
          </Table.Th>
          <Table.Th>
            <Title fw={400} size={16} c="gray.7">
              Roditeljski nalog
            </Title>
          </Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
};

export default MembersTable;

export const ParentAccount = ({ hasParentAccount }: { hasParentAccount: boolean }) =>
  hasParentAccount ? (
    <ThemeIcon ml={10} radius="xl" size="sm" color="green">
      <IconCheck style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  ) : (
    <ThemeIcon ml={10} radius="xl" size="sm" color="red">
      <IconX style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  );
