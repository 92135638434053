import { Button, Flex, LoadingOverlay, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import notifications from '@/utils/notifications';
import { useRemoveMemberFromGroup } from '@/api/hooks/groups/useRemoveMemberFromGroup';

export default function RemoveMemberFromGroup({
  memberId,
  groupId,
}: {
  memberId: string;
  groupId: string;
}) {
  const { mutateAsync: removeMemberFromGroup, isPending } = useRemoveMemberFromGroup();

  const handleRemoveMember = async () => {
    try {
      const response = await removeMemberFromGroup({ memberId, groupId });

      if (response) {
        notifications.showSuccessNotification({
          title: 'Uspešno',
          message: 'Uspešno ste uklonili polaznika iz grupe',
        });
        modals.closeAll();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack px="md" pos="relative">
      <LoadingOverlay visible={isPending} />
      <Text>Da li ste sigurni da želite da uklonite polaznika iz grupe?</Text>
      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button leftSection={<IconTrash size={18} />} color="red" onClick={handleRemoveMember}>
          Ukloni
        </Button>
      </Flex>
    </Stack>
  );
}
