import { Anchor, Table, Title, Text, Button, Flex, Card } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconChevronDown, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { Group } from '@/models/Group';
import useShowModal from '@/components/Modals/showModal';
import RemoveMemberFromGroup from '@/containers/Groups/Details/RemoveMemberFromGroup';

export default function GroupMembersTable({ group }: { group: Group }) {
  const showModal = useShowModal();
  const navigate = useNavigate();
  const [visibleMembers, setVisibleMembers] = useState(10);
  const members = group?.members || [];

  const openDeleteModal = (memberId: string) => {
    const groupId = group.id;
    showModal({
      id: 'delete-member',
      title: 'Uklonite polaznika iz grupe',
      children: <RemoveMemberFromGroup memberId={memberId} groupId={groupId} />,
      size: 'md',
    });
  };

  const handleLoadMore = () => {
    setVisibleMembers((prev) => Math.min(prev + 10, members.length));
  };

  const handleRowsRender = () => {
    if (members.length === 0) {
      return (
        <Text style={{ paddingTop: '20px', textAlign: 'center' }}>
          Ova grupa trenutno nema dodeljenih polaznika.
        </Text>
      );
    }

    return members.slice(0, visibleMembers).map((member) => (
      <Table.Tr key={member.id}>
        <Table.Td
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Anchor
            onClick={() => navigate(`/members/${member.id}`)}
            c="gray.9"
            fw={500}
            size="md"
            underline="hover"
            ff="heading"
          >
            {member.full_name}
          </Anchor>
          <IconTrash
            onClick={() => openDeleteModal(member.id)}
            style={{ cursor: 'pointer', height: '18px', color: 'red' }}
          />
        </Table.Td>
      </Table.Tr>
    ));
  };

  return (
    <Card px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover>
        <Table.Thead bg="#F6F8FA">
          <Table.Tr style={{ borderBottom: 'none' }}>
            <Table.Th>
              <Title fw={400} size={16} c="gray.7">
                Ime i prezime
              </Title>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{handleRowsRender()}</Table.Tbody>
      </Table>

      {visibleMembers < members.length && (
        <Flex justify="center" mt="md">
          <Button variant="subtle" onClick={handleLoadMore} rightSection={<IconChevronDown />}>
            Prikaži više polaznika
          </Button>
        </Flex>
      )}
    </Card>
  );
}
