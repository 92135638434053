import { Button, Card, Flex, rem, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import { useGroupDetails } from '@/api/hooks/groups/useGroupDetails';
import { Group } from '@/models/Group';
import GroupCoachesTable from '@/components/Groups/Details/GroupCoachesTable';
import AddCoachesToGroup from './AddCoachesToGroup';
import useShowModal from '@/components/Modals/showModal';

export default function GroupCoaches() {
  const showModal = useShowModal();
  const { id } = useParams<{ id: string }>();
  const { data: group, isLoading, error } = useGroupDetails(id as string);

  if (error) {
    return <div>Došlo je do greške</div>;
  }

  const openModal = () =>
    showModal({
      id: 'add-coaches-to-group',
      title: 'Dodajte trenere u grupu',
      children: <AddCoachesToGroup group={group as Group} />,
      closeOnClickOutside: false,
    });

  return (
    <Card mt={20} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Flex pt={10} pb={12} px={16} justify="space-between" align="center">
        <Title fw={600} size={20} c="gray.9">
          Treneri
        </Title>

        <Button
          onClick={openModal}
          leftSection={<IconPlus style={{ width: rem(14), height: rem(14) }} />}
          variant="outline"
        >
          Dodaj trenere
        </Button>
      </Flex>
      {isLoading ? <TableSkeletonLoading /> : <GroupCoachesTable group={group as Group} />}
    </Card>
  );
}
